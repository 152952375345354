import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const sessionReplayTracking = sessionReplayPlugin();
amplitude.add(sessionReplayTracking);

// We provide the Amplitude instance to the
// context but is later initialized  in alalytics.ts
export default defineNuxtPlugin(() => {
  return {
    provide: {
      amplitude,
    },
  };
});
