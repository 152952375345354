<script setup lang="ts">
import type { NuxtError } from "#app";

defineProps<{ error: NuxtError }>();
const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div class="text-sm font-semibold">
    <AppHeader />
    <div class="mt-4">
      <div class="container">
        <div
          class="flex flex-col gap-10 items-center mt-20 lg:mt-64 text-center"
        >
          <div class="text-5xl">{{ error?.statusMessage }}</div>
          <div class="text-3xl">Error: {{ error?.statusCode }}</div>
          <button
            class="btn bg-purple-light hover:bg-black hover:text-white"
            @click="handleError"
          >
            Go to dashboard
          </button>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>
