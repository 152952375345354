import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const appInsightsString = config.public.APP_INSIGHTS_CONNECTION_STRING;

  const appInsightsDisabledForDevelopment = appInsightsString === "disabled" && config.public.IS_DEVELOPMENT;
  if (appInsightsString && !appInsightsDisabledForDevelopment) {
    const defaultStore = useDefaultStore();
    const user = defaultStore.user;

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: appInsightsString,
        /* ...Other Configuration Options... */
        enableAutoRouteTracking: true,
      },
    });

    appInsights.loadAppInsights();
    // @ts-expect-error
    window.appInsights = appInsights;
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
      appInsights.trackException(error as any, {
        //instance: instance?.toString(),
        info: info,
        userId: user.userId,
        twitterHandle: user.twitterHandle,
      });
    };
  }
});
