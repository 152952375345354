import type { Brand, BrandName } from "./types";

export async function getBrand(brand: BrandName): Promise<Brand> {
  if (brand === "marinade") {
    const marinade = await import("../marinade");
    return marinade.brand;
  }

  if (brand === "switchboard") {
    const switchboard = await import("../switchboard");
    return switchboard.brand;
  }

  throw new Error(`Brand ${brand} not found`);
}

export function getBrandNameForOrigin(origin: string): BrandName | undefined {
  if (
    origin === "https://evangelist.marinade.finance" ||
    origin === "https://marinade.dev.evangelist.gg"
  ) {
    return "marinade";
  }
  if (origin === "https://evangelist.switchboard.xyz" ||
      origin === "https://switchboard.dev.evangelist.gg"
  ) {
    return "switchboard";
  }

  return undefined;
}
