import { default as privacy_45policyAKAaVod6xeMeta } from "/github/workspace/src/pages/articles/privacy-policy.vue?macro=true";
import { default as termsVUFDXLbdgFMeta } from "/github/workspace/src/pages/articles/terms.vue?macro=true";
import { default as _91id_93gRoIQRF9daMeta } from "/github/workspace/src/pages/campaign/[id].vue?macro=true";
import { default as campaignsvkQykbgvRYMeta } from "/github/workspace/src/pages/campaigns.vue?macro=true";
import { default as indexSSuivmG44lMeta } from "/github/workspace/src/pages/index.vue?macro=true";
import { default as marinadecjjaLksznxMeta } from "/github/workspace/src/pages/landing/marinade.vue?macro=true";
import { default as switchboardLInBCjygo7Meta } from "/github/workspace/src/pages/landing/switchboard.vue?macro=true";
import { default as not_45allowed6AteEGhnGNMeta } from "/github/workspace/src/pages/not-allowed.vue?macro=true";
import { default as past_45campaignsAQdyOBEPrWMeta } from "/github/workspace/src/pages/past-campaigns.vue?macro=true";
import { default as indexaxqPar6qN8Meta } from "/github/workspace/src/pages/profile/index.vue?macro=true";
import { default as settingsiuQ8xabcfLMeta } from "/github/workspace/src/pages/profile/settings.vue?macro=true";
import { default as verifyaQdD67XyMyMeta } from "/github/workspace/src/pages/verify.vue?macro=true";
export default [
  {
    name: "articles-privacy-policy",
    path: "/articles/privacy-policy",
    meta: privacy_45policyAKAaVod6xeMeta || {},
    component: () => import("/github/workspace/src/pages/articles/privacy-policy.vue")
  },
  {
    name: "articles-terms",
    path: "/articles/terms",
    meta: termsVUFDXLbdgFMeta || {},
    component: () => import("/github/workspace/src/pages/articles/terms.vue")
  },
  {
    name: "campaign-id",
    path: "/campaign/:id()",
    component: () => import("/github/workspace/src/pages/campaign/[id].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/github/workspace/src/pages/campaigns.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/github/workspace/src/pages/index.vue")
  },
  {
    name: "landing-marinade",
    path: "/landing/marinade",
    meta: marinadecjjaLksznxMeta || {},
    component: () => import("/github/workspace/src/pages/landing/marinade.vue")
  },
  {
    name: "landing-switchboard",
    path: "/landing/switchboard",
    meta: switchboardLInBCjygo7Meta || {},
    component: () => import("/github/workspace/src/pages/landing/switchboard.vue")
  },
  {
    name: "not-allowed",
    path: "/not-allowed",
    meta: not_45allowed6AteEGhnGNMeta || {},
    component: () => import("/github/workspace/src/pages/not-allowed.vue")
  },
  {
    name: "past-campaigns",
    path: "/past-campaigns",
    component: () => import("/github/workspace/src/pages/past-campaigns.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/github/workspace/src/pages/profile/index.vue")
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    component: () => import("/github/workspace/src/pages/profile/settings.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyaQdD67XyMyMeta || {},
    component: () => import("/github/workspace/src/pages/verify.vue")
  }
]