<script setup lang="ts">
interface Props {
  type?: string;
  canCreatePost?: boolean;
}

withDefaults(defineProps<Props>(), {
  type: "classic",
  canCreatePost: false,
});

const emit = defineEmits(["opened"]);

function openNewPostModal() {
  globalEmit("app::openTweetEditor");
  emit("opened");
}
</script>

<template>
  <button
    v-if="type == 'header'"
    class="btn p-0 lg:p-2 w-6 h-6 lg:w-auto lg:h-auto lg:px-4"
    :class="{
      'bg-black opacity-50 text-white': !canCreatePost,
      'btn-dark': canCreatePost,
    }"
    :disabled="!canCreatePost"
    @click="openNewPostModal"
  >
    <IconsPlusIcon :class="{ 'fill-white': !canCreatePost }" />
    <span class="hidden lg:block">New Post</span>
  </button>
  <button
    v-else-if="type == 'mobile-header'"
    class="flex gap-4 items-center"
    :disabled="!canCreatePost"
    @click="openNewPostModal"
  >
    <IconsPlusIcon class="w-8 h-8" />
    <span>New Post</span>
  </button>
  <button
    v-else
    class="btn bg-purple-light"
    :class="{
      'hover:bg-black hover:text-white hover:fill-white': canCreatePost,
    }"
    :disabled="!canCreatePost"
    @click="openNewPostModal"
  >
    <IconsPlusIcon />
    <span>New Post</span>
  </button>
</template>
