export default defineNuxtRouteMiddleware((to) => {
  // const { $amplitude } = useNuxtApp();

  // $amplitude.track("Page Viewed", {
  //   page: to.name,
  //   path: to.fullPath,
  // });

  // @ts-expect-error
  window.EvangelistTracker?.trackEvent({
    name: "Page Viewed",
    options: {
      page: to.name,
      path: to.fullPath,
    },
  });
});
